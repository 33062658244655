import React, { useEffect, useState } from 'react';
import { useAuth } from '../../gateway/web2Login/oAuth';
import { BsPerson, BsSend, BsSendFill, BsXCircle } from 'react-icons/bs';
import { Button, Modal } from 'react-bootstrap';
import { differenceInCalendarDays } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useWallet } from '../../gateway/web3Login/walletAuth';
import { useFetchOwnerTokens } from '../UserProfile/useFetchUserInfo';
import { validateAccountId } from '../UserProfile/useFetchUserInfo'; // Ensure this path is correct

import verse from '../../assets/verseAD.jpg';
import { fetchNftSalesByownerId } from '../Market/Components/priceUtils';
import { Link } from 'react-router-dom';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const MARKET_CONTRACT_NAME = process.env.MARKET_CONTRACT_NAME;

const AboutComponent = () => {
    return (
        <div className="other-pages-container">
            <div className="activities-container-box">
                <div className="activities-container">
                    <div className="activities-header">
                        <div className="header-title">About</div>
                        <div className="header-timer"></div>
                    </div>
                </div>
            </div>

            <div className="pages-container">
                <div className="inner-container">
                    <div className="header">Community Event</div>
                    <div className="sub-header">Let's build a beautiful community together!</div>
                </div>
            </div>

            <div className="pages-container">
                <div className="inner-container">
                    <div className="header">Community Event</div>
                    <div className="sub-header">Let's build a beautiful community together!</div>
                </div>
            </div>
        </div>
    );
};

export default AboutComponent;
