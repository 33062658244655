import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsSearch, BsTags, BsHeart, BsChevronLeft, BsBoxSeam } from 'react-icons/bs';

const MarketMenu = ({ active, setActive }) => {
    const navigate = useNavigate();
    const handleBack = () => { navigate(-1); };

    const menuItems = [
        { url: 'explore', name: 'Explore', icon: <BsSearch />, route: '/explore', style: { background: '#FFB43D', borderRadius: '15px' } },
        { url: 'sales', name: 'Sales', icon: <BsTags />, route: '/sales', style: {} },
        { url: 'offers', name: 'Offers', icon: <BsBoxSeam />, route: '/offers', style: {} },
        { url: 'favorite', name: 'Favorite', icon: <BsHeart />, route: '/favorite', style: {} },
    ];

    const hoverStyle = {
        background: '#FFB43D',
    };

    const MenuButton = ({ icon, name, onClick, defaultStyle }) => {
        const [style, setStyle] = useState(defaultStyle);
        return (
            <div
                onClick={onClick}
                onMouseEnter={() => setStyle({ ...defaultStyle })}
                onMouseLeave={() => setStyle(defaultStyle)}
                className="buttonStyle"
                style={style}
            >
                {icon}
                <div className="MenuButtonName">{name}</div>
            </div>
        );
    };

    return (
        <div className="MarketMenu">
            <div className="backward-container">
                <div className="backward-box">
                    <div className="backward-button" onClick={handleBack}>
                        <BsChevronLeft />
                    </div>
                    <span className="collection-title">Back</span>
                </div>
            </div>

            <div className="MarketSubMenu">
                {menuItems.map((item) => (
                    <MenuButton
                        key={item.url}
                        icon={item.icon}
                        name={item.name}
                        onClick={() => setActive(item.url)}
                        defaultStyle={{
                            ...(active === item.url ? hoverStyle : {}),
                            borderBottom: active === item.url ? '2px solid #FFB43D' : '',
                        }}
                        hoverStyle={hoverStyle}
                    />
                ))}
            </div>
        </div>
    );
};

export default MarketMenu;
