import React from 'react';

const Pagination = ({ page, setPage, totalPages, isLoading }) => {
    const handleNextPage = () => {
        if (!isLoading && page < totalPages) {
            setPage(page + 1);
        }
    }

    const handlePrevPage = () => {
        if (!isLoading && page > 1) {
            setPage(page - 1);
        }
    }

    return (
        <div className="CollectionsPagination">
            <div className="PaginationContainer">
                <button className="PaginationButtons" onClick={handlePrevPage} disabled={page === 1 || isLoading}>
                    {"<"}
                </button>
                <span className="PaginationTag">
                    Page {page} of {totalPages}
                </span>
                <button className="PaginationButtons" onClick={handleNextPage} disabled={page === totalPages || isLoading}>
                    {">"}
                </button>
            </div>
        </div>
    );
};

export default Pagination;
