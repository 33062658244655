import React, { useEffect, useMemo, useState } from 'react';
import crypto from 'crypto-browserify';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsPlus, BsCheckCircle, BsXCircle, BsChevronDown, BsChevronUp, BsSearch } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { nearToYocto } from '../../../utils';
import { useWallet } from '../../../gateway/web3Login/walletAuth';
import { uploadSeriesMetadata } from './NFTUploader';
import { shareOptions } from '../Map/IconOptions';
import { useFetchOwnerTokens, useFetchUserInfo } from '../../UserProfile/useFetchUserInfo';
import { replaceIpfsUrl } from '../Home';
import { useSearch } from '../../Market/Components/useSearchFilter';
import ExclusiveSpinner from '../../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const FinalMintModal = ({
    show, onHide, imageUrl, isLoading, setIsLoading, nftPrice, setNftPrice,
    handleRegenerateClick, seriesData, selectedSeries, title,
    inputErrors, handleSeriesChange, setShowAddSeriesModal,
    handleTitleChange, handlePriceChange, handleMintClickWrapper,
    isApprovedCreator, handleApproveCreator, toggleShare, isShared, setIsShared,
}) => {
    const finalImageUrl = imageUrl || localStorage.getItem("image");
    const { userPlan } = useFetchUserInfo();
    const [showDropdown, setShowDropdown] = useState(false);

    const handleCollectionDropDown = (event) => {
        event.stopPropagation(); // Prevents the event from bubbling up the event chain
        setShowDropdown(prevState => !prevState);
    };

    const handleCollectionSelection = (series, event) => {
        event.stopPropagation(); // Stop click event from propagating further
        handleSeriesChange(series);
        setShowDropdown(false); // Ensures the dropdown closes
    };

    if (typeof selectedSeries !== 'object') {
        const seriesToFilter = selectedSeries;
        const seriesProducts = seriesData.filter(item => item.series_id.toString() == seriesToFilter);
        if (seriesProducts.length > 0) {
            selectedSeries = seriesProducts[0];
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.CollectionDiv') && showDropdown) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [showDropdown]);

    return (
        <Modal size="lg" centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Capture your feelings in a digital masterpiece!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : finalImageUrl ? (
                    <div className="MintNFTPreviewContainer">
                        <div className="InformationBox">
                            <LazyLoadImage
                                className="GeneratedImage"
                                alt="Generated"
                                effect="blur"
                                src={finalImageUrl}
                                placeholderSrc={finalImageUrl}
                            />
                            <div className="TitleCollectionArea">
                                <div className="TitleBox">
                                    {/* {isApprovedCreator === null ? (
                                        <p>Checking approval status...</p>
                                    ) : isApprovedCreator ? (
                                        <p>{account_id} is an approved creator.</p>
                                    ) : (
                                        <div>
                                            <p>you are not an approved creator!</p>
                                            <button onClick={handleApproveCreator}>Approve as Creator</button>
                                        </div>
                                    )} */}

                                    <span className="ImageTitle">Insert a title for NFT</span>
                                    <input
                                        className={`TitleInputBox ${inputErrors.nftTitle ? 'input-error' : ''}`}
                                        type="text"
                                        placeholder="Title"
                                        value={title}
                                        onChange={handleTitleChange}
                                    />
                                    {inputErrors.nftTitle && <p className="error-message">Please enter a title.</p>}
                                </div>
                                <div className="CollectionBox">
                                    <span className="SelectCollection">Select Collection</span>

                                    <div className="CollectionDiv">
                                        {userPlan === 'Platinum' ? (
                                            <div className="CollectionSelectorAdd" onClick={() => setShowAddSeriesModal(true)}>
                                                <BsPlus className="UploadIcon" />
                                            </div>
                                        ) : (
                                            <div className="CollectionSelectorAdd CollectionSelectorDisabled" title="Only Diamond and Platinum users can design a collection">
                                                <BsPlus className="UploadIcon" />
                                            </div>
                                        )}

                                        <div className="CollectionListed" onClick={handleCollectionDropDown}>
                                            <div className="CollectionTitle">
                                                {(selectedSeries && selectedSeries.metadata && selectedSeries.series_id) ? selectedSeries.metadata.title : 'Select a collection'}
                                            </div>

                                            <div className="DropDownIcon">
                                                {showDropdown ? <BsChevronUp /> : <BsChevronDown />}
                                            </div>

                                            <div className="DropdownCollections" style={{ display: showDropdown ? 'block' : 'none' }}>
                                                {showDropdown && (
                                                    <>
                                                        {/* <div
                                                            className="CollectionsList"
                                                            onClick={() => handleCollectionSelection(null)}
                                                        >
                                                            Select a collection
                                                        </div> */}
                                                        {seriesData && seriesData.length > 0 ? (
                                                            seriesData.map((series, index) => {
                                                                const seriesModifiedUrl = replaceIpfsUrl(series?.metadata?.media);
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="CollectionsList"
                                                                        value={series}
                                                                        onClick={(e) => handleCollectionSelection(series, e)}
                                                                    >
                                                                        {series.metadata && (
                                                                            <div className="SeriesItem">
                                                                                <LazyLoadImage
                                                                                    alt={series.metadata.title}
                                                                                    effect="blur"
                                                                                    src={seriesModifiedUrl}
                                                                                    placeholderSrc={seriesModifiedUrl}
                                                                                    className="SeriesImage"
                                                                                />
                                                                                <span className="SeriesTitle">{series.metadata.title || `Series ${series.series_id}`}</span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            <div className="CollectionsList" disabled>Loading series...</div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {inputErrors.series == true && <p className="error-message">Please select a series.</p>}

                                </div>

                                <div className="SharePriceBoxes">

                                    <div className="ShareBox">
                                        <h1 className="do-you-share">Are you sharing your NFT on the map?</h1>
                                        <div className="emoji-selection">
                                            {shareOptions.map((option, index) => (
                                                <div
                                                    key={index}
                                                    className={`emoji-button ${isShared === option.sharing ? 'selected' : ''}`}
                                                    onClick={() => toggleShare(option.sharing)}
                                                    title={option.sharing}
                                                >
                                                    {isShared === option.sharing ? option.selectedIcon : option.notSelectedIcon}


                                                </div>
                                            ))}
                                        </div>
                                        {inputErrors.share && <p className="error-message">Please select an option.</p>}
                                    </div>

                                    {/* {isApprovedCreator === null ? (
                                        <p>Checking approval status...</p>
                                    ) : isApprovedCreator ? (
                                        <p>{account_id} is an approved creator.</p>
                                    ) : (
                                        <div>
                                            <p>you are not an approved creator!</p>
                                            <button onClick={handleApproveCreator}>Approve as Creator</button>
                                        </div>
                                    )} */}

                                    <div className="PriceBox">
                                        <p className="NFTPrice">Initial price per NFT (in NEAR)</p>
                                        <p className="PriceInputBox">{nftPrice}</p>
                                        {/* <input
                                        className={`PriceInputBox ${inputErrors.nftPrice ? 'input-error' : ''}`}
                                        type="number"
                                        value={nftPrice}
                                        onChange={handlePriceChange}
                                        placeholder="Initialized price per NFT"
                                        disabled
                                    /> */}
                                        {/* {inputErrors.nftPrice && <p className="error-message">Please insert NFT price.</p>} */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>No image generated!</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="CloseImageButton" onClick={handleRegenerateClick}>
                    Regenerate Image
                </div>
                <div className="SubmitImageButton" disabled={isLoading} onClick={() => handleMintClickWrapper(isShared)}>
                    Mint
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default FinalMintModal;

export const AddSeriesModal = ({ show, onHide, handleApproveCreator, isApprovedCreator, fetchSeriesDetails }) => {
    const { signedAccountId, callMethod } = useWallet();
    const [formData, setFormData] = useState({
        collectionName: '',
        collectionDescription: '',
        selectedImage: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({ name: false, description: false, image: false });

    const { ownerTokens } = useFetchOwnerTokens(signedAccountId);
    // const uploadedImages = [image07, image08, image09, image10, image11, image12];

    const updateFormData = (key, value) => setFormData(prev => ({ ...prev, [key]: value }));
    const handleInputChange = (key) => (e) => updateFormData(key, e.target.value);
    const handleSelectImage = (image) => updateFormData('selectedImage', image);
    const { query, handleSearchChange, filterBySearch } = useSearch();

    const validateInputs = () => {
        const errors = {
            name: !formData.collectionName.trim(),
            description: !formData.collectionDescription.trim(),
            image: !formData.selectedImage,
        };
        setInputErrors(errors);
        return Object.values(errors).every(error => !error);
    };

    const hashFunction = async (input) => {
        if (!input) {
            throw new Error('hashFunction received undefined input');
        }
        const hash = crypto.createHash('sha256');
        try {
            hash.update(input);
            return hash.digest('base64');
        } catch (error) {
            console.error('Error hashing input:', error);
            throw error;
        }
    };

    const createSeriesMetadata = async () => {
        const userDate = new Date().getTime().toString();
        const metadataIPFSUrl = await uploadSeriesMetadata(
            formData.collectionName,
            formData.collectionDescription,
            formData.selectedImage,
            userDate
        );

        if (!metadataIPFSUrl) {
            throw new Error('Failed to get proper metadata from NFT storage');
        }

        const { seriesMetadataUrl, seriesImageUrl } = metadataIPFSUrl;
        const response = await fetch(seriesMetadataUrl);
        const data = await response.json();
        if (!data.image) {
            console.error('No image data available to hash:', data);
            throw new Error('Invalid image data received');
        }
        const mediaHashBase64 = await hashFunction(data.image);
        const referenceHashBase64 = await hashFunction(JSON.stringify(data.reference || {}));  // Ensure `data.reference` is not undefined

        return {
            title: formData.collectionName,
            description: formData.collectionDescription,
            media: seriesImageUrl,
            media_hash: mediaHashBase64,
            issued_at: userDate,
            reference: seriesMetadataUrl,
            reference_hash: referenceHashBase64,
        };
    };

    const uploadSeriesData = async (metadata) => {
        const seriesId = Math.floor(Math.random() * 1000000).toString();
        const royaltyMap = { "6h9jvd.testnet": 500 }; // 5.00%

        await callMethod(NFT_CONTRACT_NAME, "create_series", {
            id: seriesId,
            metadata,
            royalty: royaltyMap,
            price: nearToYocto(0.01).toString(),  // 24 Zeros
        }, 300000000000000, nearToYocto(0.01).toString()); // Gas and deposit
    };

    const handleCreateSeries = async () => {
        if (!validateInputs()) return;

        setIsLoading(true);
        try {
            const metadata = await createSeriesMetadata();
            await uploadSeriesData(metadata);
            fetchSeriesDetails();
            setFormData({ collectionName: '', collectionDescription: '', selectedImage: null });
            setInputErrors({ name: false, description: false, image: false });
            onHide();
        } catch (error) {
            console.error('Error creating series:', error);
            alert('Failed to create series. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const filteredNFTs = useMemo(() => {
        return filterBySearch(ownerTokens);
    }, [ownerTokens, query]);

    return (
        <Modal centered show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Add New Series</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : (
                    <div className="AddCollectionCotainer">
                        {/* {isApprovedCreator === null ? (
                            <p>Checking approval status...</p>
                        ) : isApprovedCreator ? (
                            <p>{account_id} is an approved creator.</p>
                        ) : (
                            <div>
                                <p>you are not an approved creator!</p>
                                <button onClick={handleApproveCreator}>Approve as Creator</button>
                            </div>
                        )} */}

                        {/* <label htmlFor="collectionName" className="InputLabel">Collection Name</label> */}
                        <input
                            className={`InputCollectionName ${inputErrors.name ? 'input-error' : ''}`}
                            type="text"
                            placeholder="Collection name"
                            value={formData.collectionName}
                            onChange={handleInputChange('collectionName')}
                        />
                        {inputErrors.name && <p className="error-message">Please enter a collection name.</p>}

                        <div className="UploadCollectionContainer">
                            <div className="collection-search-box">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        placeholder="Search artwork(s) title"
                                        value={query || ''}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="search-button">
                                        <BsSearch />
                                    </div>
                                </div>
                            </div>

                            <div className="UploadCollectionImage">
                                {filteredNFTs.map((token, index) => (
                                    <div
                                        key={index}
                                        className={`ImageContainer ${formData.selectedImage === token.metadata.media ? 'selected' : ''}`}
                                        onClick={() => handleSelectImage(token.metadata.media)}
                                    >
                                        <LazyLoadImage
                                            alt={token.metadata.title}
                                            effect="blur"
                                            src={token.metadata.media}
                                            placeholderSrc={token.metadata.media}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {inputErrors.image && <p className="error-message">Please select an image.</p>}

                        {/* <label htmlFor="collectionDescription" className="InputLabel">Description</label> */}
                        <textarea
                            className={`CollectionNameDescription ${inputErrors.description ? 'input-error' : ''}`}
                            placeholder="Collection description"
                            value={formData.collectionDescription}
                            onChange={handleInputChange('collectionDescription')}
                        />
                        {inputErrors.description && <p className="error-message">Please enter a collection description.</p>}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="CloseImageButton" onClick={onHide}>
                    <BsXCircle />
                    Cancle
                </div>
                <div className="SubmitImageButton" onClick={handleCreateSeries} disabled={isLoading}>
                    <BsCheckCircle />
                    Add
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export const ConfirmMintModal = ({ show, onHide, handleMintNFT, handleCreateNearWallet }) => {
    const { signedAccountId } = useWallet();

    const message = signedAccountId
        ? "The procedure will cost NEAR..."
        : "Oops! To mint your NFT, you must login with a NEAR wallet...";

    return (
        <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Do you want to mint?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{message}</span>
            </Modal.Body>

            <Modal.Footer>
                {signedAccountId && (
                    <>
                        <button className="CloseImageButton" onClick={onHide}>
                            <BsXCircle />
                            No
                        </button>

                        <button className="SubmitImageButton" onClick={handleMintNFT}>
                            <BsCheckCircle />
                            Yes
                        </button>
                    </>
                )}
                {!signedAccountId && (
                    <>
                        <button className="CreateWalletButton" onClick={handleCreateNearWallet}>
                            Create NEAR Wallet
                        </button>

                        <Link to="/login" className="SubmitImageButton">Login</Link>
                    </>
                )}
            </Modal.Footer>
        </Modal >
    );
};

export const CloseMintModal = ({ show, onHide, onConfirmClose }) => {
    const message = "The cost of generating the NFT will not be refunded";

    return (
        <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Do you want to cancel sharing?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{message}</span>
            </Modal.Body>

            <Modal.Footer>
                <button className="CloseImageButton" onClick={onHide}>
                    <BsXCircle />
                    No
                </button>
                <button className="SubmitImageButton" onClick={onConfirmClose}>
                    <BsCheckCircle />
                    Yes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export const ConfirmRegenerateModal = ({ show, onHide, onConfirmRegenerate }) => {
    return (
        <Modal centered show={show} onHide={onHide} className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Regeneration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to regenerate the image?
            </Modal.Body>
            <Modal.Footer>
                <>
                    <button className="CloseImageButton" onClick={onHide}>
                        <BsXCircle />
                        Cancel
                    </button>
                    <button className="SubmitImageButton" onClick={onConfirmRegenerate}>
                        <BsCheckCircle />
                        Regenerate
                    </button>
                </>
            </Modal.Footer>
        </Modal>
    );
};

