import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { replaceIpfsUrl } from '../Home/Home';
import NearIcon from '../../assets/NearIcon.svg';
import { convertWeiToMegaEther } from '../../utils';
import { NftContractModal } from './Components/NftModal';
import { BsFilter, BsSearch, BsTags } from 'react-icons/bs';
import { MarketFilterModal, useFilters, useSearch } from './Components/useSearchFilter';
import { fetchNftSalesByContractId, fetchNftSalesByownerId } from './Components/priceUtils';
import { fetchNftDetails } from '../Home/useFetchNFTDetails';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';
import Pagination from './Components/Pagination';

const ITEMS_PER_PAGE = 6;

const TabsComponent = ({ activeTab, setActiveTab }) => {
    return (
        <div className="tabs-container">
            <button
                className={`tab-button-global ${activeTab === 'global' ? 'active' : ''}`}
                onClick={() => setActiveTab('global')}
            >
                <span className="tab-text">Global Sales</span>
            </button>
            <button
                className={`tab-button-my ${activeTab === 'user' ? 'active' : ''}`}
                onClick={() => setActiveTab('user')}
            >
                <span className="tab-text">My Sales</span>
            </button>
        </div>
    );
};

const SalesContent = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [] }), []);
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const { signedAccountId, viewMethod } = useWallet();
    const [globalSales, setGlobalSales] = useState([]);
    const [userSales, setUserSales] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('global');
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [columns, setColumns] = useState(6);

    const toggleFilterModal = useCallback(() => {
        setIsFilterModalVisible((prevState) => !prevState);
    }, []);

    const fetchJsonData = useCallback(async (url) => {
        if (!url) return null;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    }, []);

    const handleNFTClick = useCallback(async (nft) => {
        if (!nft.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nft.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    }, [fetchJsonData]);

    // Update useEffect that fetches global sales
    useEffect(() => {
        const fetchGlobalSales = async () => {
            setIsLoading(true);
            try {
                const globalResult = await fetchNftSalesByContractId(viewMethod);

                const globalSalesWithNFTs = await Promise.all(globalResult.map(async (sale) => {
                    const nftDetails = await fetchNftDetails(viewMethod, sale.token_id, fetchJsonData);

                    return { ...sale, nftDetails };
                }));

                const totalSales = globalResult.length;
                setTotalPages(Math.ceil(totalSales / ITEMS_PER_PAGE));
                setGlobalSales(globalSalesWithNFTs.reverse());
            } catch (error) {
                console.error('Failed to fetch sales:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (viewMethod && fetchJsonData) {
            fetchGlobalSales();
        }
    }, [viewMethod, fetchJsonData]);

    useEffect(() => {
        const fetchUserSales = async () => {
            setIsLoading(true);
            try {
                const result = await fetchNftSalesByownerId(viewMethod, signedAccountId);

                const userSalesWithNFTs = await Promise.all(result.map(async (sale) => {
                    const nftDetails = await fetchNftDetails(viewMethod, sale.token_id, fetchJsonData);
                    return { ...sale, nftDetails };
                }));

                const totalSales = result.length;
                setTotalPages(Math.ceil(totalSales / ITEMS_PER_PAGE));
                setUserSales(userSalesWithNFTs.reverse());
            } catch (error) {
                console.error('Failed to fetch sales:', error);
            } finally {
                setIsLoading(false);
            }
        }

        if (viewMethod && signedAccountId && fetchJsonData) {
            fetchUserSales();
        };
    }, [viewMethod, signedAccountId, fetchJsonData]);

    const renderSales = useCallback((getCardWidth, sales, filters, applyFilters, query, filterBySearch, ITEMS_PER_PAGE, page) => {
        const filteredSales = applyFilters(filterBySearch(sales));
        return filteredSales.length > 0 ? (
            <div className="NFTsContainer">
                {filteredSales
                    .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                    .map((sale, index) => {
                        const nft = sale.nftDetails;
                        const modifiedUrl = replaceIpfsUrl(nft?.metadata?.media);
                        const formattedSaleCondition = parseFloat(convertWeiToMegaEther(sale.sale_conditions)).toFixed(5);

                        return (
                            <div
                                className="NFTCard"
                                key={sale.token_id || index}
                                style={{ flexBasis: getCardWidth() }}
                                onClick={() => handleNFTClick(nft)}
                            >
                                <div className="ImgContainer">
                                    <LazyLoadImage
                                        alt={nft?.metadata?.title || "NFT"}
                                        effect="blur"
                                        src={modifiedUrl || ""}
                                        placeholderSrc={modifiedUrl || ""}
                                        className="NFTImg"
                                    />
                                </div>
                                <p className="NFTName">{nft?.metadata?.title || "Unknown Title"}</p>
                                <div className="NFTInfo">
                                    {formattedSaleCondition && <BsTags />}
                                    {formattedSaleCondition}
                                    <LazyLoadImage
                                        className="NFTIcon"
                                        alt="Near Icon"
                                        effect="blur"
                                        src={NearIcon}
                                        placeholderSrc={NearIcon}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        ) : (
            <p>No Sales found</p>
        );
    }, [handleNFTClick]);

    const getCardWidth = () => {
        switch (columns) {
            case 3:
                return '40.5%';
            case 4:
                return '23.05%';
            case 5:
                return '18.15%';
            case 6:
                return '14.85%';
            default:
                return '14.85%';
        }
    }

    return (
        <div className="user-container">
            <div className="container-tags">
                <h1 className="content-tag">{activeTab === 'global' ? 'Global Sales' : 'My Sales'}</h1>
                <h1 className="artwork-desc">Discover the Best Works of Art Generated by AI with MoodWeather!</h1>
            </div>

            <div className="user-nfts">
                <div className="SearchTag">
                    <div className="SearchContainer">
                        <div className="SearchBox">
                            <input
                                type="text"
                                placeholder="Search for sellers"
                                value={query}
                                onChange={handleSearchChange}
                            />
                            <div className="SearchButton">
                                <BsSearch />
                            </div>
                        </div>
                        <div className="FunnelBox">
                            <div className="FunnelButton" onClick={toggleFilterModal}>
                                <BsFilter />
                            </div>
                        </div>
                    </div>
                </div>

                <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} />

                <div className="ColumnsControl">
                    <div className="ColumnsLabel">Columns</div>
                    <input
                        type="range"
                        min="3"
                        max="6"
                        value={columns}
                        onChange={(e) => setColumns(parseInt(e.target.value))}
                    />
                    <span className="ColumnsNumber">{columns}</span>
                </div>
            </div>

            <div className="user-collections">
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : activeTab === 'global' ? renderSales(getCardWidth, globalSales, filters, applyFilters, query, filterBySearch, ITEMS_PER_PAGE, page) : renderSales(getCardWidth, userSales, filters, applyFilters, query, filterBySearch, ITEMS_PER_PAGE, page)}
            </div>

            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                isLoading={isLoading}
            />

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <MarketFilterModal
                show={isFilterModalVisible}
                onHide={toggleFilterModal}
                onFilterChange={handleFilterChange}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={activeTab === 'global' ? globalSales : userSales}
            />
        </div>
    );
};
export default SalesContent;
