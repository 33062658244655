import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import NearIcon from '../../assets/NearIcon.svg';
import NftIcon2 from '../../assets/NFT-ICON-2.svg';
import { replaceIpfsUrl } from '../Home/Home';
import { BsSearch } from 'react-icons/bs';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';
import { fetchNftPrice } from './Components/priceUtils';
import Pagination from './Components/Pagination';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const ITEMS_PER_PAGE = 6;

const ExploreContent = ({ onSearchChange, searchQuery }) => {
    const navigate = useNavigate();
    const { viewMethod } = useWallet();
    const [collectionDetails, setCollectionDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [columns, setColumns] = useState(3);

    const handleCollectionClick = (series) => {
        const seriesId = series.series_id;
        navigate(`/market/collection/${seriesId}`, { state: { seriesId } });
    };

    const handleInputChange = (event) => {
        onSearchChange(event.target.value);
    };

    useEffect(() => {
        if (viewMethod) {
            const fetchCollectionsList = async () => {
                setIsLoading(true);
                try {
                    const seriesList = await viewMethod(NFT_CONTRACT_NAME, "get_series", { limit: null });
                    const totalItems = seriesList.length;
                    setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

                    const seriesDetails = await Promise.all(seriesList.map(async (series) => {
                        const seriesIdStr = series.series_id.toString();
                        const params = { id: seriesIdStr };
                        const seriesDet = await viewMethod(NFT_CONTRACT_NAME, "get_series_details", params);
                        const supply = await viewMethod(NFT_CONTRACT_NAME, "nft_supply_for_series", params);
                        const totalItems = parseInt(supply, 10);
                        let totalValue = 0;
                        const tokens = await viewMethod(NFT_CONTRACT_NAME, "nft_tokens_for_series", { id: seriesIdStr });
                        const nftPrices = await Promise.all(tokens.map(async (token) => {
                            const priceData = await fetchNftPrice(viewMethod, token.token_id);
                            if (priceData) {
                                const price = priceData.isListedForSale ? parseFloat(priceData.listedPrice) : 1; // 1 NEAR if not listed
                                totalValue += price;
                            } else {
                                totalValue += 1; // Default price of 1 NEAR if no price data is found
                            }
                        }));

                        return { ...seriesDet, series_id: seriesIdStr, totalItems, totalValue };
                    }));

                    setCollectionDetails(seriesDetails);
                } catch (error) {
                    console.error('Error fetching series details:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchCollectionsList();
        }
    }, [viewMethod]);

    const filteredCollectionDetails = collectionDetails.filter(series =>
        series.metadata.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const getCardWidth = () => {
        switch (columns) {
            case 3:
                return '40.5%';
            case 4:
                return '23.05%';
            case 5:
                return '18.15%';
            case 6:
                return '14.85%';
            default:
                return '14.85%';
        }
    };

    return (
        <div className="ExploreMainContainer">
            <div className="container-tagExp">
                <h1 className="ExploreTag">Explore Collections</h1>
                <h1 className="ExploreDesc">Discover the Best Works of Art Generated by AI with MoodWeather!</h1>
            </div>

            <div className="SearchColumns">
                <div className="SearchTag">
                    <div className="SearchContainer">
                        <div className="SearchBox">
                            <input
                                type="text"
                                placeholder="Search items, collections"
                                onChange={handleInputChange}
                            />
                            <div className="SearchButton">
                                <BsSearch />
                            </div>
                        </div>
                        {/* <div className="FunnelBox">
                            <div className="FunnelButton">
                                <BsFilter />
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="ColumnsControl">
                    <div className="ColumnsLabel">Columns</div>
                    <input
                        type="range"
                        min="3"
                        max="6"
                        value={columns}
                        onChange={(e) => setColumns(parseInt(e.target.value))}
                    />
                    <span className="ColumnsNumber">{columns}</span>
                </div>
            </div>

            <div className="exp-collections">
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : filteredCollectionDetails.length > 0 ? (
                    <div className="ExploreCollections" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
                        {filteredCollectionDetails
                            .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                            .map((series, index) => {
                                const seriesModifiedUrl = replaceIpfsUrl(series?.metadata?.media);
                                return (
                                    <div
                                        className="ExplorerCollectionCard"
                                        key={index}
                                        style={{ flexBasis: getCardWidth() }}
                                        onClick={() => handleCollectionClick(series)}
                                    >
                                        <div className="ImgContainer">
                                            <LazyLoadImage
                                                className="CollectionImg"
                                                alt={series.metadata.title}
                                                effect="blur"
                                                src={`${seriesModifiedUrl}`}
                                                placeholderSrc={`${seriesModifiedUrl}`}
                                            />
                                            {/* New Top Left Circle showing total items and price */}
                                            <div className="TopLeftCircle">
                                                {/* Displaying total items */}
                                                <div className="ItemInfo">
                                                    {series.totalItems}
                                                    <LazyLoadImage
                                                        className="ItemInfoImg"
                                                        alt="MoodWeather Token"
                                                        effect="blur"
                                                        src={NftIcon2}
                                                        placeholderSrc={NftIcon2}
                                                    />
                                                </div>
                                                {/* Displaying total value */}
                                                {series.totalValue > 0 && (
                                                    <div className="PriceInfo">
                                                        {series.totalValue.toFixed(2)}
                                                        <LazyLoadImage
                                                            className="PriceInfoImg"
                                                            alt="NEAR Token"
                                                            effect="blur"
                                                            src={NearIcon}
                                                            placeholderSrc={NearIcon}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="CollectionName">
                                            {series.metadata.title}
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                ) : (
                    <p>No Collection found</p>
                )}
            </div>

            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ExploreContent;
