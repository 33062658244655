import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../gateway/web2Login/oAuth';
import { BsSend, BsSendFill, BsXCircle } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { differenceInCalendarDays } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { useFetchOwnerTokens } from '../UserProfile/useFetchUserInfo';
import { validateAccountId } from '../UserProfile/useFetchUserInfo';
import verse from '../../assets/verseAD.jpg';
import { fetchNftSalesByownerId } from '../Market/Components/priceUtils';
import { Link } from 'react-router-dom';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const AdModal = ({ isLoading, setIsLoading, show, onHide, fetchAdState }) => {
    const { callMethod } = useWallet();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        // Reset the countdown and button state whenever the modal is shown
        if (show) {
            setCountdown(3);
            setIsButtonEnabled(false);
        }
    }, [show]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else {
            setIsButtonEnabled(true);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleCloseAd = async () => {
        if (!isButtonEnabled) return;
        setIsLoading(true);
        try {
            await callMethod(NFT_CONTRACT_NAME, "increment_user_ad_counter");
            await fetchAdState(); // Refresh ad state
            onHide(); // Call the callback function after ad is closed
            setIsLoading(false);
        } catch (error) {
            console.error("Error incrementing counter: ", error);
        } finally {
            onHide(); // Hide current modal
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <ExclusiveSpinner />
            ) : (
                <Modal centered show={show} onHide={handleCloseAd} className="exclusive-modal">
                    <Modal.Header closeButton={isButtonEnabled}>
                        <Modal.Title>Watch Advertisement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LazyLoadImage
                            alt="Advertisement"
                            effect="blur"
                            src={verse}
                            placeholderSrc={verse}
                            style={{ width: '100%' }}
                        />
                        <p style={{ padding: '0px', margin: '10px' }}>{countdown > 0 ? `You can close this ad in ${countdown} seconds.` : 'You can now close this ad.'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={handleCloseAd} disabled={!isButtonEnabled}>
                            <BsXCircle /> Close
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

const ActivityComponent = () => {
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const { ownerTokens } = useFetchOwnerTokens(signedAccountId);
    const [daysSinceRegistration, setDaysSinceRegistration] = useState('0');
    const [nftMinted, setNftMinted] = useState(false);
    const [nftListed, setNftListed] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const [consecutiveMinting, setConsecutiveMinting] = useState(0);
    const [mintedInWeek, setMintedInWeek] = useState(0);
    const [adState, setAdState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    // const moodCounter = useRef(1);

    // useEffect(() => {
    //     const handleBug = async () => {
    //         try {
    //             // If we dont attach '300000000000000', '1'
    //             const mood = await callMethod(NFT_CONTRACT_NAME, "set_last_user_mood", { mood: "Expressionless" });
    //             console.log(`mood ${moodCounter.current}`, mood); // Log with counter
    //             moodCounter.current += 1; // Increment counter
    //         } catch (error) {
    //             console.error("Error setting mood:", error);
    //         }
    //     };

    //     if (callMethod) {
    //         const intervalId = setInterval(handleBug, 1000); // Call handleBug every 1 second

    //         return () => clearInterval(intervalId); // Clean up interval
    //     }
    // }, [callMethod, moodCounter]);

    const handleAdClick = (index) => {
        if (index >= adState) {
            setShowAdModal(true);
        }
    };

    const fetchAdState = async () => {
        if (viewMethod && signedAccountId) {
            try {
                const currentAdState = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_state", { account_id: signedAccountId });
                setAdState(currentAdState);
            } catch (error) {
                console.error('Error fetching ad state:', error);
            }
        }
    };

    // Fetch the current ad state from the smart contract
    useEffect(() => {
        fetchAdState();
    }, [signedAccountId, viewMethod]);

    // Community Event passed days
    useEffect(() => {
        const fetchRegistrationDate = async () => {
            if (viewMethod && signedAccountId && validateAccountId(signedAccountId)) {
                try {
                    const date = await viewMethod(NFT_CONTRACT_NAME, 'get_user_first_ad_date', { account_id: signedAccountId });
                    calculateDaysSince(date);
                } catch (error) {
                    console.error('Error fetching registration date:', error);
                }
            }
        };

        const calculateDaysSince = (dateString) => {
            if (dateString) {
                const registrationDate = new Date(dateString);
                const today = new Date();
                const difference = today - registrationDate;
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                setDaysSinceRegistration(days);
            } else {
                setDaysSinceRegistration(0);
            }
        };

        fetchRegistrationDate();
    }, [viewMethod, signedAccountId]);

    // Mint an NFT and show everyone how you feel today
    useEffect(() => {
        setNftMinted(ownerTokens.length > 0);
    }, [ownerTokens]);

    // List one of your NFTs on the global market
    useEffect(() => {
        const fetchUserListings = async () => {
            if (viewMethod && signedAccountId) {
                try {
                    const result = await fetchNftSalesByownerId(viewMethod, signedAccountId);
                    setNftListed(result && result.length > 0);
                } catch (error) {
                    console.error('Error fetching user listings:', error);
                    setNftListed(false);
                }
            }
        };

        fetchUserListings();
    }, [viewMethod, signedAccountId]);

    // Daily Activities timer
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // Share your emotions on the board at least once a day for 6 days
    useEffect(() => {
        const recentMints = ownerTokens.map(token => {
            const date = new Date(token.metadata.issued_at * 1000);
            date.setHours(0, 0, 0, 0); // Normalize the date to remove time
            return date;
        });

        // Sort dates to process them in order
        recentMints.sort((a, b) => a - b);

        let maxStreak = 0;
        let currentStreak = 0;
        let mintDaysCount = recentMints.length > 0 ? 1 : 0; // Start with 1 day if there's at least one mint

        for (let i = 1; i < recentMints.length; i++) {
            const diff = differenceInCalendarDays(recentMints[i], recentMints[i - 1]);
            if (diff === 1) { // Consecutive day
                currentStreak++;
                mintDaysCount++;
            } else if (diff > 1) { // Break in streak
                maxStreak = Math.max(maxStreak, currentStreak);
                currentStreak = 1; // Reset streak
                mintDaysCount = 1; // Reset day count
            }
        }

        maxStreak = Math.max(maxStreak, currentStreak); // Compare the last streak

        setConsecutiveMinting(maxStreak);
        setMintedInWeek(`${mintDaysCount}/6`);
    }, [ownerTokens]);

    return (
        <div className="main-home-container">
            {!signedAccountId && (
                <div className="NoWalletContainer">
                    <div className="NoWalletOverlay"></div>
                    <div className="NoWalletUserArea">
                        <p>Connect your wallet to start earning MOWE and increase your level.</p>
                        <Link to="/login" className="LoginButton" style={{ background: '#ffb43d' }}>Go to Login page</Link>
                    </div>
                </div>
            )}

            <div className="activity-container">
                <div className="inner-container">
                    <div className="header">Community Event</div>
                    <div className="sub-header">Let's build a beautiful community together!</div>
                    <div className="goal-reward">
                        <span>Goal:</span> Invite 99 friends<br />
                        <span>Reward:</span> 9,999 MOWE
                    </div>
                    <div className="time-left">Congrats! {daysSinceRegistration} day(s) of activity with us!</div>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: '5%' }}></div>
                        <div className="counter">500/10,000</div>
                    </div>
                </div>
            </div>

            <div className="activity-container ads-container">
                <div className="inner-container">
                    <div className="ads-title">Ad Rewards</div>
                    <div className="ads-subtitle">By watching ads, generate up to 4 free images using AI</div>
                    <div className="ads-buttons">
                        {signedAccountId ? (
                            <>
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <div key={index} className={`ads-button ${index < adState ? 'inactive' : 'active'}`}
                                        onClick={() => handleAdClick(index)}>
                                        {index < adState ? <BsSendFill /> : <BsSend />}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className={'ads-button active'}><BsSend /></div>
                                <div className={'ads-button active'}><BsSend /></div>
                                <div className={'ads-button active'}><BsSend /></div>
                                <div className={'ads-button active'}><BsSend /></div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="activities-container-box">
                <div className="activities-container">
                    <div className="activities-header">
                        <div className="header-title">Daily Activities</div>
                        <div className="header-timer">{currentTime}</div>
                    </div>
                    <div className="activity-body">
                        <div className="activity-card">
                            <div className="activity-title">Mint</div>
                            <div className="activity-description">Create a unique NFT to express how you feel today and share it with the world.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> Mint 1 NFT<br />
                                <span>Reward:</span> 5 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: nftMinted ? '100%' : '0%' }}></div>
                                <div className="counter">{nftMinted ? '1/1' : '0/1'}</div>
                            </div>
                        </div>
                        <div className="activity-card">
                            <div className="activity-title">Chat</div>
                            <div className="activity-description">Connect with others by sending 5 messages in the global chat or directly to a friend.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> Send 5 chat messages<br />
                                <span>Reward:</span> 5 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: '0%' }}></div>
                                <div className="counter">0/5</div>
                            </div>
                        </div>
                        <div className="activity-card">
                            <div className="activity-title">List</div>
                            <div className="activity-description">Place one of your NFTs for sale on the global marketplace and reach potential buyers.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> List 1 NFT<br />
                                <span>Reward:</span> 5 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: nftListed ? '100%' : '0%' }}></div>
                                <div className="counter">{nftListed ? '1/1' : '0/1'}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="activities-container">
                    <div className="activities-header">
                        <div className="header-title">Weekly Activities</div>
                        <div className="header-timer">3 days</div>
                    </div>
                    <div className="activity-body">
                        <div className="activity-card">
                            <div className="activity-title">Buy the Best</div>
                            <div className="activity-description">Select and purchase your top 5 favorite NFTs from the marketplace.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> Buy 5 NFTs<br />
                                <span>Reward:</span> 50 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: '0%' }}></div>
                                <div className="counter">0/5</div>
                            </div>
                        </div>
                        <div className="activity-card">
                            <div className="activity-title">Sell Everything!</div>
                            <div className="activity-description">Offer 5 of your NFTs for sale in the global marketplace.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> Sell 5 NFTs<br />
                                <span>Reward:</span> 50 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: '0%' }}></div>
                                <div className="counter">0/5</div>
                            </div>
                        </div>
                        <div className="activity-card">
                            <div className="activity-title">Capture your feelings</div>
                            <div className="activity-description">Turn your emotions into digital art daily, for 6 consecutive days.</div>
                            <div className="activity-goal-reward">
                                <span>Goal:</span> Share once per day for 6 days<br />
                                <span>Reward:</span> 50 MOWE tokens
                            </div>
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${(consecutiveMinting / 6) * 100}%` }}></div>
                                {/* <div className="counter">{`${consecutiveMinting}/6`} {`${mintedInWeek}/6`}</div> */}
                                <div className="counter">{`${consecutiveMinting}/6`}</div>
                                <div className="counter"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AdModal
                show={showAdModal}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onHide={() => { setShowAdModal(false) }}
                fetchAdState={fetchAdState}
            />

        </div>
    );
};

export default ActivityComponent;
