import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import MoodWeatherLoading from '../assets/MoodWeatherLoading.svg';

const ExclusiveSpinner = () => {
    return (
        <div className="spinner-backdrop">
            <div className="spinner-container">
                <LazyLoadImage
                    alt="MoodWeather Logo"
                    effect="blur"
                    src={MoodWeatherLoading}
                    placeholderSrc={MoodWeatherLoading}
                    className="mobileLogo"
                />
                <span className="loader"></span>
            </div>
        </div>

        // <div className="spinner-backdrop">
        //     <div className="spinner-container">
        //         <div className="spinner"></div>
        //     </div>
        // </div>
    );
};

export default ExclusiveSpinner;
