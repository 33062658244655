import React, { useState } from 'react';
import crypto from 'crypto-browserify';

import { uploadNFTMetadata } from './NFTUploader';
import { ConfirmMintModal, NFTsCollection, ConfirmRegenerateModal } from '.';
import { nearToYocto } from '../../../utils';
import { useWallet } from '../../../gateway/web3Login/walletAuth';
import ExclusiveSpinner from '../../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const MintNFT = ({
    show, onHide,
    imageUrl, setImageUrl,
    userMood, setUserMood,
    userQuote, setUserQuote,
    userWeather, userCity,
    setCollectionTitleModal,
    setGenerateImageModal,
    fetchContractTokens,
}) => {
    const { signedAccountId, callMethod, viewMethod } = useWallet();
    const [imageTitle, setImageTitle] = useState(localStorage.getItem('title') || '');
    const selectedSeriesId = localStorage.getItem("selectedCollection");

    // const [selectedSeriesId, setSelectedSeriesId] = useState(localStorage.getItem("selectedCollection") || '');
    const [nftPrice, setNftPrice] = useState(1.01);
    const [isLoading, setIsLoading] = useState(false);
    const [showNFTModal, setShowNFTModal] = useState(false);
    const [mintedNFTData, setMintedNFTData] = useState(null);
    const [showRegenerateConfirmation, setShowRegenerateConfirmation] = useState(false);
    const [isShared, setIsShared] = useState('');

    const localStorageKeys = ["image", "quote", "mood", "title", "selectedCollection"];
    const retrieveLocalStorage = (key) => localStorage.getItem(key);

    const removeLocalStorageItems = (...keys) => {
        keys.forEach(key => localStorage.removeItem(key));
    };

    const uploadMetadata = async ({ imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared }) => {
        try {
            const metadataIPFSUrl = await uploadNFTMetadata(imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared);

            // console.log(imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared);

            if (!metadataIPFSUrl) {
                console.error('Failed to upload image metadata. Please try again.');
                setIsLoading(false);
                return null;
            }

            return metadataIPFSUrl;
        } catch (error) {
            console.error('Error occurred during image upload:', error);
            setIsLoading(false);
            return null;
        }
    };

    const fetchAndProcessMetadata = async (metadataUrl) => {
        try {
            const response = await fetch(metadataUrl);
            if (!response.ok) throw new Error('Failed to fetch metadata from IPFS');

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching metadata:', error);
            setIsLoading(false);
            return null;
        }
    };

    const mintNFTFunction = async (metadata, yoctoAmount, userMood) => {
        if (!yoctoAmount) {
            console.error('Invalid NEAR amount for conversion');
            setIsLoading(false);
            return;
        }

        try {
            await callMethod(NFT_CONTRACT_NAME, "nft_mint", {
                id: selectedSeriesId,
                metadata,
                receiver_id: signedAccountId,
            }, 300000000000000, '1010000000000000000000000');

        } catch (error) {
            console.error('Error minting NFT:', error);
            setIsLoading(false);
        }
    };

    const handleMintNFT = async () => {
        // Retrieve data from local storage if necessary
        if (signedAccountId && localStorageKeys.some(key => retrieveLocalStorage(key))) {
            [imageUrl, userQuote, userMood] = localStorageKeys.map(retrieveLocalStorage);
        }

        // Check if all necessary data is available
        if (!imageTitle || !userQuote || !imageUrl || !userMood || !userWeather || !userCity || !isShared) {
            console.error("Missing data for minting NFT");
            return;
        }

        // setGenerateImageModal(false);
        setIsLoading(true);

        try {
            // Current date and time in Unix epoch milliseconds format
            const now = new Date();
            const userDate = now.getTime();
            // Unhandled Rejection (ReferenceError): Cannot access 'userQuote' before initialization
            // console.log(imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared);

            // Check if the user is logged in with a wallet
            if (signedAccountId) {
                // User is logged in with a wallet
                // Check if the user has watched an ad
                const adsState = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_state", { account_id: signedAccountId });
                // console.log("Ads state: ", adsState);
                if (!localStorageKeys.some(key => retrieveLocalStorage(key)) && !adsState) {
                    alert('Please watch an ad before minting');
                    setIsLoading(false);
                    return;
                }
            } else {
                // User is not logged in with a wallet
                // Allow them to mint if they have generated an image
                if (!localStorageKeys.some(key => retrieveLocalStorage(key))) {
                    alert('Please log in with your wallet to mint the NFT');
                    setIsLoading(false);
                    return;
                }
            }

            // Upload metadata to IPFS and get the URL
            const ipfsUrl = await uploadMetadata({ imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared });
            if (!ipfsUrl) throw new Error('Failed to upload image metadata.');

            // Extract URLs from the response
            const imageIPFSUrl = ipfsUrl.imageUrl;
            const metadataIPFSUrl = ipfsUrl.metadataUrl;
            const fetchMetadataIPFSUrl = await fetchAndProcessMetadata(metadataIPFSUrl);
            if (!fetchMetadataIPFSUrl) throw new Error('Failed to fetch metadata from IPFS.');

            // Calculate hashes for media and metadata
            const hashFunction = input => crypto.createHash('sha256').update(input).digest('base64');
            const mediaHashBase64 = hashFunction(imageIPFSUrl);
            const referenceHashBase64 = hashFunction(JSON.stringify(metadataIPFSUrl));

            // Prepare metadata for minting
            const metadata = {
                title: imageTitle,
                description: userQuote,
                media: imageIPFSUrl,
                media_hash: mediaHashBase64,
                copies: 1,
                issued_at: userDate,
                // updated_at,
                reference: metadataIPFSUrl,
                reference_hash: referenceHashBase64,
            };

            // console.log(metadata);
            // console.log("Selected Series:", selectedSeriesId);

            // Convert NEAR amount to yoctoNEAR
            const yoctoAmount = nearToYocto(nftPrice);
            if (selectedSeriesId) await mintNFTFunction(metadata, yoctoAmount, userMood);

            // // Update UI and states as necessary after successful minting
            // setShowNFTModal(false);
            // setMintedNFTData(data);
        } catch (error) {
            console.error('Error during NFT minting:', error);
        } finally {
            // Finalize the minting process
            setIsLoading(false);
            setCollectionTitleModal(false);
            setGenerateImageModal(false);
        }
    };

    const handleRegenerateClick = () => {
        setShowRegenerateConfirmation(true);
    };

    const regenerateFunction = () => {
        setImageUrl('');
        setUserQuote('');
        setUserMood('');

        if (localStorageKeys.some(key => retrieveLocalStorage(key))) {
            removeLocalStorageItems(...localStorageKeys);
        }

        setCollectionTitleModal(false);
        setShowRegenerateConfirmation(false);
        setGenerateImageModal(true);
    };

    return (
        <>
            {isLoading ? (
                <ExclusiveSpinner />
            ) : (
                <>
                    <NFTsCollection
                        show={show}
                        onHide={onHide}
                        imageUrl={imageUrl}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}

                        userMood={userMood}
                        userCity={userCity}
                        userWeather={userWeather}
                        userQuote={userQuote}

                        imageTitle={imageTitle}
                        setImageTitle={setImageTitle}
                        setCollectionTitleModal={setCollectionTitleModal}

                        nftPrice={nftPrice}
                        setNftPrice={setNftPrice}

                        isShared={isShared}
                        setIsShared={setIsShared}

                        handleMintNFT={handleMintNFT}
                        handleRegenerateClick={handleRegenerateClick}
                    />

                    {showRegenerateConfirmation && (
                        <ConfirmRegenerateModal
                            show={showRegenerateConfirmation}
                            onHide={() => setShowRegenerateConfirmation(false)}
                            onConfirmRegenerate={regenerateFunction}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MintNFT;
