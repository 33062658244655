import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsCalendar3, BsFileImage, BsPerson, BsTags } from "react-icons/bs";
import { useFetchAllUsersInfo } from "../UserProfile/useFetchUserInfo";
import { getMoodIcon, getShareIcon, getWeatherIcon, getCityIcon } from "./Map/IconOptions";
import moment from 'moment';
import { truncateAddress } from '../../utils';
import { replaceIpfsUrl } from './Home';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { fetchNftPrice } from '../Market/Components/priceUtils';
import NearIcon from '../../assets/NearIcon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LikeButton } from '../Market/Components/NftModal';

const NFTBoard = ({ nft, handleNFTClick, viewType }) => {
    const walletAddress = nft?.owner_id;
    const { otherUsersPlanColor, otherUsersAvatar } = useFetchAllUsersInfo(walletAddress);
    const issueDate = nft?.metadata?.issued_at;
    const convertDate = issueDate ? moment(issueDate).format("MMM. D, YYYY") : "Not recorded";
    const mediaUrl = replaceIpfsUrl(nft.metadata.media);
    const { signedAccountId, viewMethod } = useWallet();
    const [price, setPrice] = useState(null);
    const [marketIcon, setMarketIcon] = useState(null);

    useEffect(() => {
        const fetchPrice = async () => {
            const priceData = await fetchNftPrice(viewMethod, nft.token_id);
            setPrice(priceData ? (priceData.isListedForSale ? `${priceData.listedPrice}` : `${priceData.seriesPrice}`) : '∞');
            setMarketIcon(priceData?.isListedForSale);
        };

        if (nft && nft.token_id) {
            fetchPrice();
        }
    }, [nft, viewMethod]);

    return (
        <div className={`BoardingFrame ${viewType}`}>
            <div className="NFTContent">
                <div className="BoardingContent">
                    <Link to={`/profile/${nft.owner_id}`} className="BoardingUserInfo">
                        {otherUsersAvatar ? (
                            <LazyLoadImage
                                alt="User Avatar"
                                effect="blur"
                                src={otherUsersAvatar}
                                placeholderSrc={otherUsersAvatar}
                                className="BoardingUserImg"

                            />
                        ) : (
                            <BsPerson className="BoardingUserImg" alt="No Avatar" />
                        )}
                        <div className="BoardingUserName">{truncateAddress(nft.owner_id)}</div>
                    </Link>
                    <div className="BoardingContainer">
                        <div className="BoardingNFTTitleContainer">
                            <BsFileImage className="BoardingNFTTitleIcon" />
                            <div className="BoardingNFTTitle">{nft.metadata.title}</div>
                        </div>
                        <div className="BoardingNFTUserQuote">{nft.metadata.description}</div>
                    </div>
                    <div className="BoardingNFTDateContainer">
                        <BsCalendar3 className="BoardingNFTDateIcon" />
                        <div className="BoardingNFTDate">{convertDate}</div>
                        <div className="BoardingNFTPrice">{marketIcon && <BsTags className="BoardingNFTSaleIcon" />} {price}</div>
                        <LazyLoadImage
                            alt="Near Icon"
                            effect="blur"
                            src={NearIcon}
                            placeholderSrc={NearIcon}
                        />
                    </div>
                </div>
                <div className="BoardingNFTMediaContainer">
                    <LazyLoadImage
                        alt="NFT Media"
                        effect="blur"
                        src={mediaUrl}
                        placeholderSrc={mediaUrl}
                        className="BoardingNFTMedia"
                        style={{ border: `5px solid ${otherUsersPlanColor}` }}
                        onClick={() => handleNFTClick(nft)}
                    />

                    <div className="TopRightlike">
                        <div className="nft-modal-title">
                            {signedAccountId && (
                                <LikeButton nft={nft} onLike={(liked) => console.log('Liked:', liked)} />
                            )}
                        </div>
                    </div>
                    <div className="TopLeftCircle">
                        {nft?.jsonData?.attributes?.map((attribute, attrIndex) => (
                            <span key={`${nft.token_id}-${attrIndex}`}>
                                {attribute.trait_type === 'Mood' && getMoodIcon(attribute.value)}
                                {attribute.trait_type === 'Weather' && getWeatherIcon(attribute.value)}
                                {attribute.trait_type === 'City' && getCityIcon(attribute.value)}
                                {/* {attribute.trait_type === 'Shared' && getShareIcon(attribute.value)} */}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTBoard;
